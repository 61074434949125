import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';


export const addMedia = createAsyncThunk('complimentOrders/addMedia', async ({mediaData}, {dispatch}) => {
  try {
    const response = await axiosInstance.post(`/media`, mediaData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("addMedia error:",error);
    throw error;
  }
});

export const fetchMedia = createAsyncThunk('complimentOrders/fetchMedia', async (tenant_id, {dispatch}) => {
  try {
    const response = await axiosInstance.get(`/media?filtered_tenant_id=${tenant_id ? tenant_id : ''}`);
    // dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    // dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("fetchMedia error:",error);
    throw error;
  }
});

export const deleteMedia = createAsyncThunk('complimentOrders/deleteMedia', async ( mediaId,{dispatch}) => {
  try {
    const response = await axiosInstance.delete(`/media/${mediaId}`);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return mediaId;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("deleteMedia error:",error);
    throw error;
  }
});

export const editMedia = createAsyncThunk('complimentOrders/editMedia', async ({mediaId, mediaData},{dispatch}) => {
  try {
    const response = await axiosInstance.put(`/media/${mediaId}`, mediaData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editMedia error:",error);
    throw error;
  }
});

export const updateIsPrintedComplimentOrders = createAsyncThunk('media/updateIsPrintedComplimentOrders/', async ({checked,id},{dispatch}) => {
  try {
    const response = await axiosInstance.put(`media/update-media-printed/${id}`, {is_printed: checked});
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editMedia error:",error);
    throw error;
  }
});

const initialState = {
  complimentOrders: [],
  loading: false,
  error: null,
  sortOrder: 'desc',
};

const complimentOrdersSlice = createSlice({
  name: 'complimentOrders',
  initialState,
  reducers: {
    toggleSortOrder: (state) => {
      state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc';
      state.complimentOrders.sort((a, b) => {
        return state.sortOrder === 'asc'
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addMedia.fulfilled, (state, action) => {
        state.loading = false;
        if(state.sortOrder === "desc"){
          state.complimentOrders.unshift(action.payload.data);
        } else {
          state.complimentOrders.push(action.payload.data);
        }
      })
      .addCase(addMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.complimentOrders = action.payload.data;
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.complimentOrders = state.complimentOrders.filter(media => media.id !== action.payload);
      })
      .addCase(deleteMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_delete_media;
      })
      .addCase(editMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editMedia.fulfilled, (state, action) => {
        state.loading = false;
        // debugger
        const index = state.complimentOrders.findIndex(media => media.id === action.payload.data.id);
        if (index !== -1) {
          state.complimentOrders[index] = action.payload.data;
        }
      })
      .addCase(editMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_edit_media;
      })
      .addCase(updateIsPrintedComplimentOrders.pending, (state) => {
        state.error = null;
      })
      .addCase(updateIsPrintedComplimentOrders.fulfilled, (state, action) => {
        const index = state.complimentOrders.findIndex(media => media.id === action.meta.arg.id);
        if (index !== -1) {
          state.complimentOrders[index].is_printed = action.meta.arg.checked
        }
      })
      .addCase(updateIsPrintedComplimentOrders.rejected, (state, action) => {
        state.error = action.payload || translation.could_not_edit_media;
      });
  },
});

export default complimentOrdersSlice.reducer;

export const { toggleSortOrder } = complimentOrdersSlice.actions;