import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';

export const fetchDashboard = createAsyncThunk('/dashboard', async (tenant_id ,{dispatch}) => {
  try {
    const response = await axiosInstance.get(`/dashboard?filtered_tenant_id=${tenant_id ? tenant_id : ""}`);
    return response.data;
  } catch (error) {
    console.error("fetch dashboard stats error:",error);
    throw error;
  }
});

const initialState = {
  data: {   
    Events: 0,
    Orders: 0,
    Users: 0,
    Medias: 0,
    ComplimentaryOrders: 0,
  },
  loading: false,
  error: null
};

const mediaLinksSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default mediaLinksSlice.reducer;