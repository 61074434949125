import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';


export const addUser = createAsyncThunk('users/addUser', async (userData, {dispatch}) => {
  try {
    const response = await axiosInstance.post('/users', userData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("addUser error:",error);
    throw error;
  }
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_,{dispatch}) => {
  try {
    const response = await axiosInstance.get('/users');
    // console.log("fetchUsers response :", response);
    // dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    // dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    // console.error("fetchUsers error:",error);
    throw error;
  }
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (userId,{dispatch}) => {
  try {
    const response = await axiosInstance.delete(`/users/${userId}`);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return userId;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("deleteUser error:",error);
    throw error;
  }
});

export const editUser = createAsyncThunk('users/editUser', async (userData,{dispatch}) => {
  try {
    const {id, ...restData} = userData
    const response = await axiosInstance.put(`/users/${id}`, restData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editUser error:",error);
    throw error;
  }
});

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.unshift(action.payload.data);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_delete_user;
      })
      .addCase(editUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(user => user.id === action.payload.data.id);
        if (index !== -1) {
          state.users[index] = action.payload.data;
        }
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_edit_user;
      });
  },
});

export default usersSlice.reducer;
