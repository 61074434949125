import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  timeout: 600000,
  headers: {
    // 'Connection': 'keep-alive', // Keep-alive header to maintain long connections
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        window.location.reload(); // Optional: Reload page on token expiry
      } else {
        config.headers.Authorization = `${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = localStorage.getItem('token');

    if (token && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Handle token refresh or logout scenario here
        // console.log('Token expired or invalid. Please log in again.');
        // Example: dispatch(logout()); and clear localStorage
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
