import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';


export const addMedia = createAsyncThunk('media/addMedia', async ({eventId, mediaData}, {dispatch}) => {
  try {
    const response = await axiosInstance.post(`events/${eventId}/media`, mediaData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.message, color: 'danger' }));
    console.error("addMedia error:",error);
    throw error;
  }
});

export const fetchMedia = createAsyncThunk('media/fetchMedia', async (eventId,{dispatch}) => {
  try {
    const response = await axiosInstance.get(`events/${eventId}/media`);
    // console.log("fetchMedia response :", response);
    // dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    // dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("fetchMedia error:",error);
    throw error;
  }
});

export const fetchOrderMedia = createAsyncThunk('media/fetchOrderMedia', async (orderId,{dispatch}) => {
  try {
    dispatch(clearOrderMedia());
    const response = await axiosInstance.get(`orders/${orderId}/media`);
    // console.log("fetchOrder response :", response);
    return response.data;
  } catch (error) {
    console.error("fetchOrder error:",error);
    throw error;
  }
});

export const deleteMedia = createAsyncThunk('media/deleteMedia', async ( mediaId,{dispatch}) => {
  try {
    const response = await axiosInstance.delete(`events/media/${mediaId}`);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return mediaId;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("deleteMedia error:",error);
    throw error;
  }
});

export const editMedia = createAsyncThunk('media/editMedia', async ({eventId,mediaId, mediaData},{dispatch}) => {
  try {
    const response = await axiosInstance.put(`events/${eventId}/media/${mediaId}`, mediaData);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editMedia error:",error);
    throw error;
  }
});

export const updateIsPrintedMedia = createAsyncThunk('media/updateIsPrintedMedia/', async ({checked,id},{dispatch}) => {
  try {
    const response = await axiosInstance.put(`media/update-media-printed/${id}`, {is_printed: checked});
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editMedia error:",error);
    throw error;
  }
});

const initialState = {
  media: [],
  orderMedia: [],
  loading: false,
  error: null,
  template: true,
  aspectRatio:''
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    clearOrderMedia: (state) => {
      state.orderMedia = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.media.unshift(action.payload.data);
      })
      .addCase(addMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.media = action.payload?.data?.media || [];
        state.template = action.payload?.data?.template;
        state.aspectRatio = action.payload?.data?.aspect_ratio
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchOrderMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.orderMedia = action.payload.data;
      })
      .addCase(fetchOrderMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.media = state.media.filter(media => media.id !== action.payload);
      })
      .addCase(deleteMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_delete_media;
      })
      .addCase(editMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editMedia.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.media.findIndex(media => media.id === action.payload.data.id);
        if (index !== -1) {
          state.media[index] = action.payload.data;
        }
      })
      .addCase(editMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_edit_media;
      })
      .addCase(updateIsPrintedMedia.pending, (state) => {
        state.error = null;
      })
      .addCase(updateIsPrintedMedia.fulfilled, (state, action) => {
        if(action.meta.arg.type === "event"){
          const index = state.media.findIndex(media => media.id === action.meta.arg.id);
          if (index !== -1) {
            state.media[index].is_printed = action.meta.arg.checked
          }
        } else {
          const index = state.orderMedia.media.findIndex(media => media.id === action.meta.arg.id);
          if (index !== -1) {
            state.orderMedia.media[index].is_printed = action.meta.arg.checked
          }
        }
      })
      .addCase(updateIsPrintedMedia.rejected, (state, action) => {
        state.error = action.payload || translation.could_not_edit_media;
      });
  },
});

export const { clearOrderMedia } = mediaSlice.actions;
export default mediaSlice.reducer;
